<template>
  <div class="star-rating">
    <span
        v-for="index in 5"
        :key="index"
        class="star"
        :class="{ 'clickable': !readOnly }"
        @click="setRating(index)"
    >
      <span
          class="filled"
          :style="{ display: index <= currentRating ? 'block' : 'none' }"
      >
        &#9733;
      </span>
      <span class="empty">
        &#9733;
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentRating: this.value
    };
  },
  watch: {
    value(newValue) {
      this.currentRating = newValue;
    }
  },
  methods: {
    setRating(value) {
      if (!this.readOnly) {
        this.currentRating = value;
        this.$emit('input', value);
      }
    }
  }
}
</script>

<style scoped>
.star {
  position: relative;
  font-size: 40px;
}

.filled {
  position: absolute;
  left: 0;
  top: -3px;
  height: 100%;
  color: #ffd700;
}

.empty {
  color: #ddd;
}

.star.clickable {
  cursor: pointer;
}
</style>