<template>
  <div id="app">
    <transition name="fade">
      <SplashScreen v-if="showSplash" :logo="logo" />
    </transition>
    <router-view v-show="!showSplash" />
  </div>
</template>

<script>
import SplashScreen from './components/SplashScreen.vue';

export default {
  name: 'App',
  components: {
    SplashScreen
  },
  data() {
    return {
      showSplash: true,
      logo: require('@/assets/logo_dorba.png') // Убедитесь, что путь к логотипу правильный
    };
  },
  mounted() {
    setTimeout(() => {
      this.showSplash = false;
    }, 2000);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

body {
  margin: 0px;
  background-color: #0038af;
  height: 100%;
}

html {
  height: 100%;
}
</style>