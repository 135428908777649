// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import BarcodeScanner from '../components/BarcodeScanner.vue';
import ProductInfo from '../components/ProductInfo.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: BarcodeScanner,
        meta: { title: 'Сканер штрих-кода' }
    },
    {
        path: '/product-info',
        name: 'ProductInfo',
        component: ProductInfo,
        props: route => {
            try {
                return { product: JSON.parse(route.query.product) };
            } catch (e) {
                console.error('Ошибка при разборе данных продукта:', e);
                return { product: null };
            }
        },
        meta: { title: 'Информация о продукте' }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Ваше приложение';
    next();
});

export default router;