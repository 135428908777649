<template>
  <div class="splash-screen">
    <div class="logo-container" :class="{ 'animate-pulse': animateLogo }">
      <img :src="logo" :alt="altText" class="logo" @load="onLogoLoad">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      required: true
    },
    altText: {
      type: String,
      default: 'Логотип приложения'
    },
    showLoadingText: {
      type: Boolean,
      default: true
    },
    animateLogo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      logoLoaded: false
    }
  },
  methods: {
    onLogoLoad() {
      this.logoLoaded = true;
      this.$emit('logo-loaded');
    }
  }
}
</script>

<style scoped>
.splash-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #0038af;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 100%;
  height: auto;
  max-width: 80vw;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.animate-pulse {
  animation: pulse 2s infinite ease-in-out;
}
</style>