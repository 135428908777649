<template>
  <div style="height:100%">
  <div :class="{'header-animation': animateHeader}">
    <header>
      <img class="logo-header" src="@/assets/logo_dorba.png" alt="logo">
    </header>
    <div v-if="showConfirmation" class="confirmation-message">
      Ваш отзыв отправлен на проверку, спасибо, что используете DORBA. Давайте покупать только качественную продукцию.
    </div>
  </div>
  <div class="product-info">
    <div class="product-header">
      <h2>{{ product.name }}</h2>
      <div class="rating">
        <star-rating :rating="averageRating" :read-only="true" />
        <span>{{ averageRating }} ({{ reviews.length }} отзывов)</span>
      </div>
    </div>

    <div class="reviews-container">
      <h3>Отзывы</h3>
      <button @click="openReviewModal" class="btn-primary">Оставить свой отзыв</button>
      <ul v-if="reviews.length" class="review-list">
        <li v-for="review in reviews" :key="review.id" class="review-item">
          <div class="review-header">
            <star-rating :rating="review.rating" :read-only="true" />
            <span class="review-date">{{ formatDate(review.created_at) }}</span>
          </div>
          <p class="review-content">{{ review.content }}</p>
        </li>
      </ul>
      <p v-else class="no-reviews">Отзывов пока нет.</p>
    </div>

    <review-modal v-if="showReviewModal" @close="closeReviewModal">
      <template #header>
        <h3>Оставить свой отзыв</h3>
      </template>
      <template #body>
        <form @submit.prevent="submitReview" class="review-form">
          <div class="form-group">
            <label for="review-rating">Ваша оценка:</label>
            <star-rating-review
                :value="newReview.rating"
                @input="updateRating"
                :read-only="false"
            />
          </div>
          <div class="form-group">
            <label for="review-content">Ваш отзыв:</label>
            <textarea
                id="review-content"
                v-model="newReview.content"
                required
                :maxlength="maxReviewLength"
            ></textarea>
            <span class="char-count">{{ newReview.content.length }}/{{ maxReviewLength }}</span>
          </div>
          <div class="form-group">
            <label for="review-expiration">Срок годности до:</label>
            <input
                id="review-expiration"
                v-model="newReview.expiration_date"
                type="date"
            />
          </div>
        </form>
      </template>
      <template #footer>
        <button @click="closeReviewModal" class="btn-secondary">Отмена</button>
        <button @click="submitReview" :disabled="isSubmitting" class="btn-primary">
          {{ isSubmitting ? 'Отправка...' : 'Отправить отзыв' }}
        </button>
      </template>
    </review-modal>

    <div v-if="error" class="error-message">{{ error }}</div>
  </div>
  </div>
</template>

<script>
import StarRating from './StarRating.vue';
import StarRatingReview from './StarRatingReview.vue';
import ReviewModal from './ReviewModal.vue';

export default {
  components: {
    StarRating,
    StarRatingReview,
    ReviewModal
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      averageRating: this.product.average_rating || 0,
      reviews: this.product.reviews || [],
      showReviewModal: false,
      newReview: this.getEmptyReview(),
      isSubmitting: false,
      error: null,
      maxReviewLength: 500,
      animateHeader: false,
      contentVisible: false,
      showConfirmation: false
    };
  },
  mounted() {
    // Запуск анимации при монтировании компонента
    setTimeout(() => {
      this.animateHeader = true;
    }, 500); // Задержка перед началом анимации
    setTimeout(() => {
      this.contentVisible = true;
    }, 2000);
  },
  computed: {
    today() {
      return new Date().toISOString().split('T')[0];
    }
  },
  methods: {
    getEmptyReview() {
      return {
        product_id: this.product.id,
        content: '',
        rating: 0,
        expiration_date: ''
      };
    },
    openReviewModal() {
      this.showReviewModal = true;
    },
    closeReviewModal() {
      this.showReviewModal = false;
      this.newReview = this.getEmptyReview();
    },
    formatDate(dateString) {
      if (!dateString) return 'Не указано';
      return new Date(dateString).toLocaleDateString();
    },
    async submitReview() {
      this.isSubmitting = true;
      this.error = null;
      try {
        const response = await fetch('https://api.dorbaapp.kz/api/review', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.newReview),
        });
        const data = await response.json();
        if (data.status === 'success') {
          this.newReview = this.getEmptyReview();
          this.closeReviewModal();
          this.showConfirmationMessage();
        } else {
          throw new Error(data.message || 'Произошла ошибка при отправке отзыва');
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isSubmitting = false;
      }
    },
    showConfirmationMessage() {
      this.showConfirmation = true;
      setTimeout(() => {
        this.showConfirmation = false;
      }, 5000); // Сообщение будет видно 3 секунды
    },
    updateRating(value) {
      this.newReview.rating = value;
    },
    // ... другие методы
  },

}
</script>

<style scoped>
.product-info {
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px;
  background-color: white;
  height: 100%;
}

.product-header {
  margin-bottom: 50px;
}

.product-header h2 {
  margin: 25px;
}

.rating span {
  margin-left: 10px;
  font-size: 14px;
  color: #666;
  display: block;
}

.reviews-container {
  margin-bottom: 20px;
}

.reviews-container h3 {
  font-size: 30px;
}

.review-list {
  list-style-type: none;
  padding: 0;
}

.review-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.review-header {
  margin-bottom: 10px;
}

.review-date {
  font-size: 12px;
  color: #666;
  display: block;
}

.review-content {
  margin-bottom: 10px;
}

.review-moderation-date {
  font-size: 12px;
  color: #666;
  text-align: right;
}

.no-reviews {
  color: #666;
  font-style: italic;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.3s;
  margin-right: 40px;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.review-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 15px;
}

.form-group textarea,
.form-group input {
  width: 90%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.char-count {
  font-size: 12px;
  color: #666;
  text-align: right;
  display: block;
  margin-top: 5px;
}

.error-message {
  color: #dc3545;
  margin-top: 10px;
  padding: 10px;
  background-color: #f8d7da;
  border-radius: 4px;
}

.header-animation {
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 2000;
}

header {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0038af;
  transition: height 1s ease-in-out;
}

.header-animation header {
  height: 100px;
}

.header-animation main {
  display: block;
}

.logo-header {
  width: 250px;
  padding-top: 15px;
}

#review-content {
  height: 150px;
}
.confirmation-message {
  background-color: #4caf50;
  color: white;
  padding: 15px;
  text-align: center;
  border-radius: 20px;
  margin: 10px 0;
  position: fixed;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
  border: 5px solid #68d868;
  font-size: 20px;
}
</style>