<template>
  <div>
  <div v-if="loading" class="loader">Мы ищем ваш товар...</div>
  <div v-if="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
  <div class="scanner-container">
    <div class="video-overlay" v-show="isScanning">
      <video ref="video" autoplay></video>
    </div>
    <div v-if="!errorMessage" class="guide">
      <p :class="{'text-fade-in': !hasMoved, 'text-move-up': hasMoved}">
        {{ guideText }}
      </p>
    </div>
    <button
        @click="startScanner"
        :class="{'scan-button-fade-in': !hasMoved, 'scan-button-move-up': hasMoved}"
        :disabled="isScanning"
        aria-label="Начать сканирование штрих-кода"
    >
      {{ isScanning ? 'Сканирование...' : 'Сканировать' }}
    </button>

    <div v-if="showPopup" :class="{'popup-fade-in': showPopup}" role="dialog" aria-labelledby="popup-title">
      <div class="popup-content">
        <h2 id="popup-title">Мы нашли товар!</h2>
        <p class="product-name"><b>{{ productName }}</b></p>
        <br>
        <p class="submit-question">Подскажите, название товара верно указано?</p>
        <div class="popup-buttons">
          <button @click="onConfirm" aria-label="Подтвердить продукт">Да</button>
          <button @click="onCancel" aria-label="Отменить и сканировать снова">Нет</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from '@zxing/library';

export default {
  data() {
    return {
      isScanning: false,
      showPopup: false,
      productName: '',
      productBarcode: '',
      codeReader: null,
      errorMessage: null,
      hasMoved: false,
      loading: false,
      guideText: 'Чтобы начать, пожалуйста, отсканируйте штрихкод товара, и мы постараемся найти его для Вас',
    };
  },
  methods: {
    startScanner() {
      this.hasMoved = true;
      this.isScanning = true;
      this.errorMessage = null;
      this.codeReader = new BrowserMultiFormatReader();

      this.codeReader.decodeFromVideoDevice(null, this.$refs.video, (result, err) => {
        if (result) {
          this.sendBarcodeToBackend(result.text);
        }
        if (err && err.name !== 'NotFoundException') {
          this.error = 'Ошибка сканирования: ' + err.message;
          this.stopScanner();
        }
      }).catch(err => {
        this.error = 'Не удалось получить доступ к камере: ' + err.message;
        this.stopScanner();
      });
    },
    async sendBarcodeToBackend(barcode) {
      try {
        this.loading = true;
        const url = `https://api.dorbaapp.kz/api/product/${encodeURIComponent(barcode)}`;
        const response = await fetch(url);
        if (!response.ok) throw new Error('Ошибка сервера');
        const data = await response.json();

        if (data.status === 'success') {
          if (data.new === true) {
            this.productName = data.name;
            this.productBarcode = barcode;
            this.showPopup = true;
          } else if (this.$router) {
            this.$router.push({
              path: '/product-info',
              query: { product: JSON.stringify(data.product) }
            });
          }
        } else if (data.status === 'error') {
          this.errorMessage = data.message || 'Неизвестная ошибка'; // Отображаем сообщение об ошибке
        }
      } catch (error) {
        this.errorMessage = 'Ошибка при отправке запроса: ' + error.message;
      } finally {
        this.stopScanner();
        this.hasMoved = false;
        this.isScanning = false;
        this.loading = false;
      }
    },
    stopScanner() {
      if (this.codeReader) {
        this.codeReader.reset();
      }
      const videoElement = this.$refs.video;
      if (videoElement && videoElement.srcObject) {
        const stream = videoElement.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
        videoElement.srcObject = null;
      }
      this.isScanning = false;
    },
    async onConfirm() {
      try {
        const response = await fetch('https://api.dorbaapp.kz/api/product/submit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.productName,
            barcode: this.productBarcode
          })
        });

        if (!response.ok) {
          throw new Error('Ошибка при отправке данных');
        }

        const result = await response.json();

        if (this.$router) {
          this.$router.push({
            path: '/product-info',
            query: { product: JSON.stringify(result.product) }
          });
        }
      } catch (error) {
        console.error('Ошибка:', error);
        this.error = 'Ошибка при отправке данных: ' + error.message;
      } finally {
        this.showPopup = false;
        this.isScanning = false;
      }
    },
    onCancel() {
      this.showPopup = false;
      this.isScanning = false;
      this.hasMoved = false;
      this.guideText = 'К сожалению, мы не нашли товар который вы искали, но мы учимся искать лучше. Поэтому попробуйте позже или отсканируйте другой товар';
    },
  }
};
</script>

<style scoped>
.scanner-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-overlay video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes floatText {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.scan-button {
  position: fixed;
  bottom: -150px;
  width: 100%;
  height: 300px;
  background-color: #83aaff;
  border: 5px solid white;
  border-radius: 50% 50% 0 0;
  color: white;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  transition: all 0.5s ease-in-out;
}

.scan-button.scanning {
  border-radius: 0;
  padding-top: 5px;
  transform: translateY(100px);
}

.guide {
  width: 100%;
  text-align: center;
  animation: floatText 2s ease-in-out infinite;
}

.text-fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.text-move-up {
  animation: moveUp 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes moveUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100px);
  }
}

.scan-button-fade-in, .scan-button-move-up {
  width: 100%;
  height: 300px;
  background-color: #83aaff;
  border: 5px solid white;
  border-radius: 50%;
  position: fixed;
  bottom: -150px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  font-size: 30px;
}

.scan-button-fade-in {
  opacity: 0;
  animation: fadeInButton 0.5s ease-in-out forwards;
}

@keyframes fadeInButton {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.scan-button-move-up {
  animation: moveUpButton 0.5s ease-in-out forwards;
}

@keyframes moveUpButton {
  from {
    border-radius: 50%;
    padding-top: 50px;
    transform: translateY(0px);
  }
  to {
    border-radius: unset;
    padding-top: 5px;
    transform: translateY(100px);
  }
}

.video {
  width: 95%;
  height: 95%;
  position: relative;
}

.popup-fade-in {
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  top: 220px;
  position: fixed;
  margin: 10px;
  opacity: 0;
  animation: fadeInButton 0.5s ease-in-out forwards;
}

.popup-content {
  text-align: center;
}

.popup-buttons {
  margin-top: 20px;
}

.popup-buttons button {
  margin: 0 25px;
  padding: 15px 35px;
  background-color: #0038af;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 20px;
}

.popup-buttons button:hover {
  background-color: #002e88;
}

.guide p {
  font-size: 20px;
  color: white;
  padding-top: 100px;
  padding-left: 60px;
  padding-right: 60px;
}

.product-name {
  background-color: #dae7ff;
  padding: 10px;
}

.cancel-message {
  text-align: center;
  margin-top: 20px;
  color: white;
}

.scan-again-button {
  margin-top: 20px;
  padding: 15px 35px;
  background-color: #0038af;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 20px;
}

.scan-again-button:hover {
  background-color: #002e88;
}
.error-message {
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
  background-color: #c0acac;
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  border: 5px solid #a58a8a;
  font-size: 20px;
  margin-top: 200px;
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: bold;
  z-index: 1000;
  background-color: white;
  padding: 100%;
  opacity: 50%;
  width: 200px;
}
</style>