<template>
  <div class="star-rating">
    <span
        v-for="index in 5"
        :key="index"
        class="star"
        :class="{ 'clickable': !readOnly }"
        @click="setRating(index)"
    >
      <span
          class="filled"
          :style="{ width: getStarFillWidth(index) + '%' }"
      >
        &#9733;
      </span>
      <span class="empty">
        &#9733;
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setRating(value) {
      if (!this.readOnly) {
        this.$emit('input', value);
      }
    },
    getStarFillWidth(index) {
      const fullStars = Math.floor(this.rating);
      const fraction = this.rating - fullStars;

      if (index <= fullStars) {
        return 100; // Полностью закрашенная звезда
      } else if (index === fullStars + 1) {
        return fraction * 100; // Частично закрашенная звезда
      } else {
        return 0; // Пустая звезда
      }
    }
  }
}
</script>

<style scoped>
.star-rating {
  display: inline-flex;
  align-items: center;
}

.star {
  position: relative;
  font-size: 24px;
}

.filled {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  color: #ffd700;
  white-space: nowrap;
  overflow: hidden;
}

.empty {
  color: #ddd;
}

.star.clickable {
  cursor: pointer;
}

.star.clickable:hover .filled {
  color: #ffed85;
}
</style>